import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  clientList: [],
  filteredClientList: [],
};

const clientManagementSlice = createSlice({
  name: "clientManagement",
  initialState,
  reducers: {
    setClientList: (state, action) => {
      state.clientList = action.payload;
      state.filteredClientList = action.payload;
    },
    filterClientList: (state, action) => {
      state.filteredClientList = state.clientList.filter(
        (item) =>
          item.name?.toLowerCase()?.includes(action.payload.toLowerCase()) ||
          item.name?.toLowerCase()?.includes(action.payload.toLowerCase())
      );
    },
  },
});

export const { setClientList, filterClientList } =
  clientManagementSlice.actions;

export default clientManagementSlice.reducer;
