import { configureStore } from "@reduxjs/toolkit";

import { apiSlice } from "./api/apiSlice";

import stockManagementReducer from "../features/stockManagement/stockManagementSlice";
import clientManagementReducer from "../features/clientManagement/clientManagementSlice";

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    stockManagement: stockManagementReducer,
    clientManagement: clientManagementReducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});
