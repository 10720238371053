import React from "react";
import "./App.css";

import { Routes, Route, Navigate } from "react-router-dom";
import LoadingComponent from "./components/LoadingComponent";
const Header = React.lazy(() => import("./features/header/Header"));
const SideBar = React.lazy(() => import("./features/sideBar/SideBar"));
const CategoryList = React.lazy(() =>
  import("./features/categoryManagement/CategoryList")
);
const StockList = React.lazy(() =>
  import("./features/stockManagement/StockList")
);
const AddStock = React.lazy(() =>
  import("./features/stockManagement/AddProduct")
);
const ProductDetails = React.lazy(() =>
  import("./features/stockManagement/ProductDetails")
);
const TotalStock = React.lazy(() =>
  import("./features/stockManagement/TotalStock")
);
// const UpdateProduct = React.lazy(() =>
//   import("./features/stockManagement/UpdateProduct")
// );
const OrderList = React.lazy(() =>
  import("./features/orderManagement/OrderList")
);
const CreateOrder = React.lazy(() =>
  import("./features/orderManagement/CreateOrder")
);
const TrackOrder = React.lazy(() =>
  import("./features/orderManagement/TrackOrder")
);
const ViewOrder = React.lazy(() =>
  import("./features/orderManagement/ViewOrder")
);
const ViewInvoice = React.lazy(() =>
  import("./features/orderManagement/ViewInvoice")
);
const ClientList = React.lazy(() =>
  import("./features/clientManagement/ClientList")
);
const ClientDetails = React.lazy(() =>
  import("./features/clientManagement/ClientDetails")
);
const LedgerList = React.lazy(() =>
  import("./features/ledgerManagement/LedgerList")
);
const ViewLedger = React.lazy(() =>
  import("./features/ledgerManagement/ViewLedger")
);
const CouponList = React.lazy(() =>
  import("./features/couponManagement/CouponList")
);

const FirmManagement = React.lazy(() =>
  import("./features/firmManagement/FirmManagement")
);

const UnitConversion = React.lazy(() =>
  import("./features/unitConversion/UnitConversion")
);

function App() {
  return (
    <div className="App">
      <React.Suspense fallback={<LoadingComponent open={true} />}>
        <SideBar />
        <Header />
        <Routes>
          <Route path="/" element={<Navigate to="/" />} />
          <Route path="/categoryList" element={<CategoryList />} />
          <Route path="/stockList" element={<StockList />} />
          <Route path="/addProduct" element={<AddStock />} />
          <Route path="/stockDetails/:id" element={<ProductDetails />} />
          <Route path="/totalStock/:id" element={<TotalStock />} />
          <Route path="/updateProduct/:id" element={<AddStock />} />
          <Route path="/orderList" element={<OrderList />} />
          <Route path="/createOrder" element={<CreateOrder />} />
          <Route path="/trackOrder/:orderId" element={<TrackOrder />} />
          <Route path="/viewOrder/:orderId" element={<ViewOrder />} />
          <Route path="/viewInvoice/:orderId" element={<ViewInvoice />} />
          <Route path="/clientList" element={<ClientList />} />
          <Route path="/clientDetails/:id" element={<ClientDetails />} />
          <Route path="/ledgerList" element={<LedgerList />} />
          <Route path="/viewLedger/:clientId" element={<ViewLedger />} />
          <Route path="/couponList" element={<CouponList />} />
          <Route path="/firmList" element={<FirmManagement />} />
          <Route path="/unitConversion" element={<UnitConversion />} />
          {/* <Route path="*" element={<Navigate to="/" />} /> */}
        </Routes>
      </React.Suspense>
    </div>
  );
}

export default App;
