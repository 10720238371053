import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  stockList: [],
  filteredStockList: [],
};

const stockManagementSlice = createSlice({
  name: "stockManagement",
  initialState,
  reducers: {
    setProductList: (state, action) => {
      state.stockList = action.payload;
      state.filteredStockList = action.payload;
    },
    filterProductList: (state, action) => {
      state.filteredStockList = state.stockList.filter(
        (item) =>
          item.skuCode.toLowerCase().includes(action.payload.toLowerCase()) ||
          item.productName
            .toLowerCase()
            .includes(action.payload.toLowerCase()) ||
          item.hsnCode.toLowerCase().includes(action.payload.toLowerCase())
      );
    },

    filterProductListFirmCategoryWise: (state, action) => {
      let stockListToSet = state.stockList;
      if (action.payload.searchKey) {
        stockListToSet = stockListToSet.filter(
          (item) =>
            item.skuCode
              .toLowerCase()
              .includes(action.payload.searchKey.toLowerCase()) ||
            item.productName
              .toLowerCase()
              .includes(action.payload.searchKey.toLowerCase()) ||
            item.hsnCode
              .toLowerCase()
              .includes(action.payload.searchKey.toLowerCase())
        );
      }
      if (action.payload.firm) {
        if (action.payload.firm?.id === -1) {
          stockListToSet = stockListToSet.filter((item) => item.firm === null);
        } else {
          stockListToSet = stockListToSet.filter(
            (item) => item.firm?.id === action.payload.firm.id
          );
        }
      }
      if (action.payload.category) {
        if (action.payload.category?.id === 0) {
          stockListToSet = stockListToSet.filter(
            (item) => item.category === null
          );
        } else {
          stockListToSet = stockListToSet.filter(
            (item) => item.category?.id === action.payload.category.id
          );
        }
      }

      state.filteredStockList = stockListToSet;
    },
  },
});

export const {
  setProductList,
  filterProductList,
  filterProductListFirmCategoryWise,
} = stockManagementSlice.actions;

export default stockManagementSlice.reducer;
