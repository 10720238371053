const config = Object.freeze({
  // baseUrl: "http://192.168.12.41:9090/",
  // baseUrl: "http://192.168.12.57:8006/",
  // baseUrl: "http://localhost:8080/",
  // baseUrl: "http://staging.watsoo.com:8085/sfa-store-management/", // staging
  // baseUrl: "http://staging.watsoo.com:8085/sfa-store-management/",
  // baseUrl: "https://sfaorders.nyggs.com/backend/", // prod
  // permissionBaseUrl: "https://sfa.nyggs.com/livebackend/", // prod
  // permissionBaseUrl: "https://sfastaging.nyggs.com/backend/", // staging
  // baseUrl: "https://sfaordersstaging.nyggs.com/backend/", // new staging
  // permissionBaseUrl: "https://sfaordersstaging.nyggs.com/permissionbackend/", // new staging
  baseUrl: "https://orders.himasalesportal.com/backend/",
  permissionBaseUrl: "https://orders.himasalesportal.com/permissionbackend/",
  apiName: {
    getAllProducts: "api/get/all/products",
    // getAllUnits: "api/getAll/units",
    getAllUnits: "api/get/company/units",
    imageUpload: "v1/uploadFile",
    addProduct: "api/save/product",
    updateProduct: "api/update/product",
    // getProductDetailsOld: "api/getAll/transactions",
    getProductDetails: "api/getAll/transaction/by/productId",
    getProductBySku: "api/get/product/by/sku",
    updateStockDetails: "api/update/stock",
    // getAllOrdersOld: "getAll/orders",
    // getAllOrdersOld: "getAll/ordersV2",
    getOrderListFilterOptions: "get/all/order/page/data/list",
    getOrderIdListByClientName: "get/order/list/by/clientName",
    getAllOrders: "getAll/ordersV3",
    getAllClients: "getAll/clients/byUserAndHrmsId",
    // getClientWiseProductPriceOld: "get/clientwise/product/price",
    getClientWiseProductPrice: "get/clientwise/product/priceV2",
    getClientProductsPrice: "get/client/all/product/price",
    clientWiseProductSet: "save/clientwise/product/price",
    clientWiseProductUpdate: "update/clientwise/product/price",
    getClientNotificationConfig: "get/all/email/notification/for/client",
    setClientNotificationConfig: "save/email/notification/for/client",
    createOrder: "create/order",
    getOrderStatus: "get/order/details/byOrderId",
    updateOrderStatus: "update/orderStatus",
    getViewOrderDetail: "get/order/by/orderID",
    getAllOrderStatus: "get/all/order/status",
    getAllLedger: "get/all/client/account/details",
    getPaymentDetails: "get/client/payment/details/byId",
    getOrderIdsByClientId: "get/client/orderIds",
    saveClientTransaction: "create/new/transaction",
    getStateList: "get/all/state",
    getPermissionList:
      "configuration_controller/v1/get/sfa/user/sales/permission",
    getAllProductDetails: "api/get/column/details/for/product",
    uploadProductByExcel: "api/add/product/byExcel",
    getCouponListByCompanyId: "get/all/coupon/by/company/id",
    createCouponCode: "create/coupon/code",
    getCouponCodeDetails: "get/coupon/by/coupon/code",
    updateCoupon: "update/coupon/code",
    getCouponPrice: "get/coupon/price",
    changeCouponStatus: "change/coupon/status",
    getTokenType: "api/get/token/type",
    getOutstandingAmount: "api/get/outstanding/amount",
    getEditedProductPrice: "get/client/wise/product/price/after/edit",
    getCouponListForPrice: "get/coupon/list/under/price",
    getFirmList: "get/all/firm",
    getCategoryList: "get/all/product/category",
    createCategory: "create/product/catagory",
    updateCategory: "update/category",
    createFirm: "create/firm",
    updateFirm: "update/firm",
    getLocalitiesByCompanyId: "get/all/locality/by/company/id",
    getCompanyConfigurationList: "get/company/configuration",
    getAllUnits: "api/get/company/units",
    createUnit: "api/create/unit/list",
    createSubUnit: "save/subunit",
    updateSubUnit: "update/subunit",
    getUnitsByProductId: "get/all/units/by/productId",
  },
});

export default config;
